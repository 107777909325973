// Sentry.init({
// 	dsn: "https://5e86bedd24ac4bf38d234b44ca7dab3b@o1100122.ingest.sentry.io/6125067",
// });
// Set background image from data-bg-src and data-bg-src-2x attributes
import { Tooltip, Modal } from "bootstrap"

function initPhoneMask() {
	var selector = document.querySelectorAll('.phone-mask');
	Inputmask({"mask": "+375 (99) 999-99-99"}).mask(selector);

}

// Init AOS plugin (blocks animations)
function initAnimations(duration){
	if(typeof(AOS) !== 'undefined'){
		if(!duration){duration=500;}
		AOS.init({
			easing: 'ease-out-cubic',
			once: true,
			offset: 50,
			duration: duration,
		});
	}
}

function setCookie(name, value, options = {}) {
	options = {
		path: '/',
		// при необходимости добавьте другие значения по умолчанию
		...options
	};

	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}

	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}

	document.cookie = updatedCookie;
}

// возвращает куки с указанным name,
// или undefined, если ничего не найдено
function getCookie(name) {
	let matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

function readParams () {
	var params = {};
	window.location.search.slice(1).split('&').forEach(elm => {
		if (elm === '') return;
		let spl = elm.split('=');
		const d = decodeURIComponent;
		params[d(spl[0])] = (spl.length >= 2 ? d(spl[1]) : true);
	});
	return params;
}

function onFromSubmit (e) {
	const btn = this.querySelector('[type="submit"]')
	btn.setAttribute('disabled', 'disabled')

	const loadingSpan = document.createElement("span")
	loadingSpan.className = "spinner-border spinner-border-sm me-2"
	loadingSpan.setAttribute('role', "status")
	loadingSpan.setAttribute('aria-hidden', "true")
	btn.replaceChildren(loadingSpan)
	btn.append("Отправляем...")
}

document.addEventListener("scroll", function () {
	const toggleElement = document.getElementById("phone-toggle");
	if (!toggleElement) return;

	if (window.scrollY > 150) {
		toggleElement.classList.add("active");
	} else {
		toggleElement.classList.remove("active");
	}
});

window.addEventListener('load', function () {
	const urlParams = new URLSearchParams(window.location.search);
	const hashParam = urlParams.get('hash');

	if (hashParam) {
		const elm = document.getElementById(hashParam)
		if (elm) {
			elm.scrollIntoView({ behavior: 'smooth' });
		}
	}
});

// document.ready section
document.addEventListener("DOMContentLoaded", function(){
	initAnimations();
	initPhoneMask();

	if (document.getElementById("notWorking")) {
		const myModalAlternative = new Modal('#notWorking')
		myModalAlternative.show()
	}

	let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	tooltipTriggerList.forEach(function (tooltipTriggerEl) {
		new Tooltip(tooltipTriggerEl, {});
	});

	for(var i=0; i<document.forms.length; i++){
		var form = document.forms[i];
		form.addEventListener("submit", onFromSubmit,false);
	}
});